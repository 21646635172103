<template>
  <div>
    <b-modal id="modal-editor" size="md" :title="editorTitle" ok-only no-stacking centered>
      <template #default="">
        <b-container v-if="employee.id" fluid class="p-0">
          <b-row class="details-row">
            <b-col>
              <div class="label">{{ $t('view.employees.editor.firstName') }}</div>
              <div>{{ employee.firstName }}</div>
            </b-col>
          </b-row>
          <b-row class="details-row">
            <b-col>
              <div class="label">{{ $t('view.employees.editor.lastName') }}</div>
              <div>{{ employee.lastName }}</div>
            </b-col>
          </b-row>
          <b-row class="details-row">
            <b-col>
              <div class="label">{{ $t('view.employees.editor.email') }}</div>
              <div>{{ employee.email }}</div>
            </b-col>
          </b-row>
          <b-row><b-col>
              <b-form-group :label="$t('view.employees.editor.role')" label-class="label">
                <b-form-select v-if="employee.roleId !== 'company_owner'" v-model="employee.roleId" :options="roles"
                  class="form-control" :disabled="isBusy" />
                <b-form-input v-else :disabled="true" :value="$t('role.' + employee.roleId)" />
              </b-form-group>
            </b-col></b-row>
          <!-- 


                <b-row><b-col>
                  <b-form-group :label="$t('view.employees.editor.firstName')" label-class="label">
                    <b-form-input v-model="employee.firstName" type="text" :disabled="true"/>
                  </b-form-group>
                </b-col></b-row>
                <b-row><b-col>
                  <b-form-group :label="$t('view.employees.editor.lastName')" label-class="label">
                    <b-form-input v-model="employee.lastName" type="text" :disabled="true"/>
                  </b-form-group>
                </b-col></b-row>
                <b-row><b-col>
                  <b-form-group :label="$t('view.employees.editor.email')" label-class="label">
                    <b-form-input v-model="employee.email" type="email" :disabled="true"/>
                  </b-form-group>
                </b-col></b-row>
                <b-row><b-col>
                  <b-form-group :label="$t('view.employees.editor.role')" label-class="label">
                    <b-form-select v-if="employee.roleId !== 'company_owner'" v-model="employee.roleId" :options="roles" class="form-control" :disabled="isBusy"/>
                    <b-form-input v-else :disabled="true" :value="$t('role.' + employee.roleId)"/>
                  </b-form-group>
                </b-col></b-row> -->
          <div v-if="isEventNotificationSubscriptionEnabled">
            <b-row><b-col>
                <div><b>Benachrichtigungen</b></div>
              </b-col></b-row>
            <b-row v-for="(subscription, index) in eventNotificationSubscriptions" :key="index">
              <b-col class="d-flex flex-column justify-content-center">
                {{ subscription.email }}
              </b-col>
              <b-col cols="auto">
                <b-button variant="link" class="ml-2" :disabled="isBusy" style="color: red;"
                  @click="onDeleteEventNotificationSubscription(subscription)">
                  Entfernen
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="d-flex flex-column justify-content-center">
                <!-- <b-form-group> -->
                <input v-model="newEventNotificationSubscriptionEmail" type="text" :disabled="isBusy" />
                <!-- </b-form-group> -->
              </b-col>
              <b-col cols="auto">
                <b-button variant="outline-success" class="ml-2" :disabled="isBusy"
                  @click="onAddEventNotificationSubscription()">
                  Hinzufügen
                </b-button>
              </b-col></b-row>
          </div>
        </b-container>

        <b-container v-else>
          <b-row><b-col>
              <b-form-group :label="$t('view.employees.editor.name')" label-class="label">
                <b-form-input v-model="employee.name" type="text" :disabled="isBusy" />
              </b-form-group>
            </b-col></b-row>
          <b-row><b-col>
              <b-form-group :label="$t('view.employees.editor.email')" label-class="label">
                <b-form-input v-model="employee.email" type="email" :disabled="isBusy" />
              </b-form-group>
            </b-col></b-row>
          <b-row><b-col>
              <b-form-group :label="$t('view.employees.editor.language')" label-class="label">
                <b-form-select v-model="employee.languageIso" :options="languages" class="form-control"
                  :disabled="isBusy" />
              </b-form-group>
            </b-col></b-row>
          <b-row><b-col>
              <b-form-group :label="$t('view.employees.editor.role')" label-class="label">
                <b-form-select v-model="employee.roleId" :options="roles" class="form-control" :disabled="isBusy" />
              </b-form-group>
            </b-col></b-row>
        </b-container>
      </template>
      <template #modal-footer="{ cancel }">
        <div v-if="employee.roleId !== 'company_owner'" class="d-flex w-100">
          <b-button variant="outline-danger" v-if="employee.id" :disabled="isBusy" @click="onDeleteClicked()">{{
            $t('modal.button.delete') }}</b-button>
          <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy" @click="cancel()">{{
            $t('modal.button.cancel') }}</b-button>
          <b-button variant="success" class="ml-2" :disabled="isBusy" @click="onSaveClicked()">
            {{ employee.id ? $t('modal.button.save') : $t('view.employees.editor.button.invite') }}
          </b-button>
        </div>
        <div v-else class="d-flex w-100">
          <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy" @click="cancel()">{{
            $t('modal.button.cancel') }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import EventNotificationSubscriptionApi from '@/api/event-notification-subscription'
import Toaster from '@/utils/toaster'

export default {
  components: {
  },
  props: {
    employee: { type: Object, required: true },
    isBusy: { type: Boolean, default: false },
  },
  data: function () {
    return {
      languages: [
        { value: 'de', text: this.$t('language.de') },
        { value: 'en', text: this.$t('language.en') },
      ],
      roles: [
        { value: 'company_user', text: this.$t('role.company_user') },
        { value: 'company_manager', text: this.$t('role.company_manager') },
        // 'company_financial',
        // 'company_owner',
        // 'vehicle_user',
      ],
      newEventNotificationSubscriptionEmail: '',
      eventNotificationSubscriptions: [],
    }
  },
  computed: {
    editorTitle() {
      if (this.employee.id) {
        return this.$t('view.employees.editor.title.edit')
      } else {
        return this.$t('view.employees.editor.title.invite')
      }
    },
    company() {
      return this.$store.getters['User/company'](this.$route.params.companyId)
    },
    isEventNotificationSubscriptionEnabled() {
      return this.company?.isEventNotificationSubscriptionEnabled
    }
  },
  methods: {
    onSaveClicked: function () {
      this.$emit('save')
    },
    onDeleteClicked: function () {
      this.$emit('delete')
    },
    onAddEventNotificationSubscription: async function () {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (regex.test(this.newEventNotificationSubscriptionEmail)) {
        try {
          await EventNotificationSubscriptionApi.addSubscription(this.company.id, this.employee.id, {
            eventType: 'recording.*',
            email: this.newEventNotificationSubscriptionEmail,
          })
          this.newEventNotificationSubscriptionEmail = ''
          this.eventNotificationSubscriptions = await EventNotificationSubscriptionApi.getSubscriptions(this.company.id, this.employee.id)
        } catch (error) {
          console.log(error)
          Toaster.showError(this, error)
        }
      } else {
        Toaster.showError(this, "Bitte geben Sie eine gültige E-Mail-Adresse ein.")
      }
    },
    onDeleteEventNotificationSubscription: async function (subscription) {
      if (subscription?.id) {
        try {
          await EventNotificationSubscriptionApi.deleteSubscription(this.company.id, this.employee.id, subscription.id)
          this.eventNotificationSubscriptions = this.eventNotificationSubscriptions.filter((s) => s.id !== subscription.id)
        } catch (error) {
          console.log(error)
          Toaster.showError(this, error)
        }
      }
    },
  },
  mounted: async function () {
  },
  watch: {
    async employee() {
      if (this.employee?.id) {
        try {
          this.eventNotificationSubscriptions = await EventNotificationSubscriptionApi.getSubscriptions(this.company.id, this.employee.id)
        } catch (error) {
          Toaster.showError(this, error)
        }
      }
    }
  },

}
</script>

<style scoped>
/* .label {
    font-size: 0.8em;
} */

.details-row {
  margin-bottom: 20px;
}

.delete-event-notification-subscription-button {
  cursor: pointer;
  color: #dc3545;
  text-decoration: underline;
}

.add-event-notification-subscription-button {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
</style>
