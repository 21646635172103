import Vue from 'vue'

export default {
    async getSubscriptions(companyId, userId) {
        console.log('getSubscriptions', companyId, userId)
        let result = await Vue.axios.get('/api/companies/' + companyId + '/users/' + userId + '/event_notification_subscription')
        console.log('getSubscriptions result', result)
        return result.data.data.map(data => {
            return {
                id: data.id,
                ...data.attributes
            }
        })
    },

    async addSubscription(companyId, userId, objectInfo) {
        let result = await Vue.axios.post('/api/companies/' + companyId + '/users/' + userId + '/event_notification_subscription', {
            data: {
                type: 'event_notification_subscription',
                attributes: objectInfo
            }
        })
        return result.data
    },

    async deleteSubscription(companyId, userId, subscriptionId) {
        let result = await Vue.axios.delete('/api/companies/' + companyId + '/users/' + userId + '/event_notification_subscription/' + subscriptionId)
        return result.data
    },
}
